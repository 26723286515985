import React from 'react'
import { css } from '@emotion/core'

const AspectRatio = (props) => {
    const { children, ratio, ...otherProps } = props
    console.log(ratio)
    return (
        <div
            css={css`
                width: 100%;
                padding-top: ${ratio || '50%'};
                &::after {
                    content: '';
                    display: block;
                }
                position: relative;
            `}
            {...otherProps}
        >
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                `}
            >
                {children}
            </div>
        </div>
    )
}

export default AspectRatio