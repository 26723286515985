import React from "react"
import { css } from '@emotion/core'
import Client from 'shopify-buy'
import { observer } from "mobx-react"
import { observable, action } from "mobx"
import { Flipper, Flipped } from "react-flip-toolkit"
import BuyButton from "./buy-button"
import AspectRatio from "./aspect-ratio"
import Img from "gatsby-image"
import Spinner from 'react-spinner'
import spinnerStyles from 'react-spinner/react-spinner.css'

@observer class CheckoutBegin extends React.Component {
    @observable isBuying = false
    @observable selectedVariant
    @observable confirmedVariant = false

    @action beginBuy = async (e) => {
        this.isBuying = true
        if (this.props.product.variants?.length === 0) {
            this.checkout()
        }
        // const checkout = await client.checkout.create()
        // client.checkout.addLineItems(checkout.id)
    }

    @action selectVariant = (variant) => {
        return (e) => {
            this.selectedVariant = variant
        }
    }

    @action confirmVariant = (e) => {
        if (this.selectedVariant) {
            this.confirmedVariant = true
            this.checkout()
        }
    }

    @action checkout = async () => {
        // Pinterest track
        window.pintrk('track', 'addtocart', {
            value: this.selectedVariant.price,
            order_quantity: 1,
            currency: 'USD'
        });

        const client = Client.buildClient({
            domain: 'fuego-flores.myshopify.com',
            storefrontAccessToken: '9d3f736e9cf875907ad400d66f17e022'
        })

        const checkout = await client.checkout.create()

        const lineItems = [{
            variantId: this.selectedVariant.id.split('Shopify__ProductVariant__')[1],
            quantity: 1
        }]
        await client.checkout.addLineItems(checkout.id, lineItems)

        window.location = checkout.webUrl
    }

    render () {
        const { product, preorder } = this.props

        return (
            <Flipper flipKey={this.isBuying}>
                {this.isBuying ? (
                    <div css={css`
                        position: relative;
                        margin-left: -1.5rem;
                        margin-right: -1.5rem;
                        padding: 1.5rem;
                        background-color: #f2f2f2;

                        margin-bottom: -1.5rem;

                        @media (min-width: 648px) {
                            margin: 0;
                        }
                    `}>
                        <h1 css={css`
                            font-size: 28px;
                            line-height: 32px;
                            margin-bottom: 13px;
                            margin-top: 1px;
                        `}>
                            Select a size
                        </h1>
                        <div css={css`
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            column-gap: 1.5rem;
                            row-gap: 1.5rem;
                        `}>
                            {product.variants.map(variant => (
                                <div
                                    key={variant.id}
                                    onClick={this.selectVariant(variant)}
                                    css={css`
                                        flex: 1;

                                        position: relative;

                                        &::after {
                                            ${this.selectedVariant?.id === variant.id && `
                                                content: '';
                                                position: absolute;
                                                top: 0; left: 0; bottom: 0; right: 0;
                                                background-color: rgba(255, 255, 255, 0.25);
                                                color: #fff;
                                            `}
                                        }
                                    `}
                                >
                                    <AspectRatio
                                        ratio='100%'
                                    >
                                        <Img
                                            fluid={variant.image.localFile.childImageSharp.fluid}
                                        />
                                        <div
                                            css={css`
                                                position: absolute;
                                                top: 0; left: 0; bottom: 0; right: 0;
                                                // background-color: #444;
                                                color: #fff;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                font-size: 40px;
                                                font-weight: bold;
                                                font-family: Helvetica;
                                                cursor: default;
                                            `}
                                            style={{
                                                fontSize: this.selectedVariant?.id === variant.id ? '55px' : ''
                                            }}
                                        >
                                            {variant.selectedOptions.find(o => o.name === 'Size').value}
                                        </div>
                                    </AspectRatio>
                                </div>
                            ))}
                        </div>
                        <div css={css`
                            margin-top: 1.5rem;
                            display: flex;
                            flex-direction: row-reverse;
                        `}>
                            <Flipped flipId='button' key='button'>
                                <BuyButton onClick={this.confirmVariant} style={{
                                    paddingTop: this.confirmedVariant ? 2 : '',
                                    paddingBottom: this.confirmedVariant ? 2 : '',
                                    width: this.confirmedVariant ? 139 : ''
                                }}>
                                    {this.confirmedVariant ? <Spinner /> : !this.selectedVariant ? 'Select size' : '$'+this.selectedVariant.price}
                                </BuyButton>
                            </Flipped>
                        </div>
                    </div>
                ) : (
                    <div css={css`
                        display: flex;
                        flex-direction: row-reverse;
                    `}>
                        <Flipped flipId='button' key='button'>
                            <BuyButton onClick={this.beginBuy}>{preorder ? 'Pre-order now' : 'Buy now'}</BuyButton>
                        </Flipped>
                    </div>
                )}
            </Flipper>
        )
    }
}

export default CheckoutBegin