import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import CheckoutBegin from "../components/checkout-begin"

const Page = ({ data }) => (
  <Layout>
    <SEO title={data.shopifyPage.title} meta={data.shopifyPage.bodySummary} />
    <div css={css`
        padding: 1.5rem;

        @media (min-width: 648px) {
            padding: 1.5rem 0;
        }

        h1 {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 13px;
            margin-top: 2px;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;

        p {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: -6px;
            }
        }

        p.legal {
            margin-top: 14px;
            font-size: 16px;
            line-height: 16px;
            &:last-child {
                margin-bottom: -4px;
            }
        }
    `}>
        <div dangerouslySetInnerHTML={{__html: data.shopifyPage.body}}></div>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query($handle: String!) {
    shopifyPage(handle: { eq: $handle }) {
      id
      title
      body
      bodySummary
    }
  }
`