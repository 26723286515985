import React from 'react'
import { css } from '@emotion/core'

const BuyButton = (props) => {
    const { children, ...otherProps } = props

    return (
        <div
            css={css`
                display: inline-block;
                text-align: center;
                background-color: hsla(243, 31%, 42%, 1);
                color: #fff;
                font-family: Helvetica;
                font-weight: 500;
                font-size: 18px;
                padding: 6px 37px;
                padding-bottom: 7px;
                border-radius: 5px;
                user-select: none;
                cursor: pointer;
                &:active {
                    opacity: 0.93;
                }
            `}
            {...otherProps}
        >
            {children}
        </div>
    )
}

export default BuyButton